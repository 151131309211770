import { MouseEventHandler } from "react"

type ButtonProps = {
    variant: 'primary' | 'secondary'
    type?: 'button' | 'submit'
    label: string
    disabled?: boolean
    onClick?: MouseEventHandler
}

const Button: React.FC<ButtonProps> = ({variant, type = 'button', label, disabled, onClick}) => {

    const colours = variant === "primary" ? `bg-orange-500 
    hover:bg-orange-600
    focus:bg-orange-600
    active:bg-orange-700
    text-white` : `bg-transparent
    text-orange-600 
    border-2
    border-orange-600 
    hover:bg-gray-50
    hover:border-orange-700 
    hover:text-orange-700 
    focus:bg-gray-50
    focus:border-orange-700 
    focus:text-orange-700 
    active:bg-gray-100`;

    return (
        <button
            type={type}
            className={`w-full disabled:opacity-50 text-lg font-semibold ${disabled ? 'pointer-events-none' : ''} ${colours} py-2 px-4 rounded-md`}
            disabled={disabled}
            onClick={onClick}
            >{label}</button>
            )
}


export default Button;