import type { NextPage } from 'next'
import Header from "../components/Header";
import Button from "../components/Button";
import { AsyncTypeahead, Input, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { useState } from 'react';
// import MenuItem from '../components/MenuItem';
// import Button from 'react-bootstrap/Button';
import { useRouter } from 'next/router';
import { getCoord } from '@turf/invariant';
import { Feature, Point } from '@turf/helpers';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { usePlausible } from 'next-plausible';

const Home: NextPage = () => {

  const router = useRouter();

  const [isLoading, setLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);

  const [fromLocation, setFromLocation] = useState<Feature<Point>>();
  const [toLocation, setToLocation] = useState<Feature<Point>>();

  const plausible = usePlausible();

  const fetchOptions = (query: string) => {
    let proximity = '';
    if (fromLocation) {
      proximity = `&proximity=${getCoord(fromLocation)}`
    }
    
    setLoading(true);
    fetch(`api/geoencoding?q=${query}${proximity}`)
      .then(resp => resp.json())
      .then(json => {
        setLoading(false);
        setOptions(json.features)
      });
  }

  // const renderMenuItemChildren = (option: any, props) => (
  //   <MenuItem title={option.text} subtitle={option.place_name} />
  // )

  const selectLocation = (value, setter) => {
    setOptions([]);
    setter(value[0]);
  }

  const goToParkingPicker = async () => {
    if (toLocation === undefined) { return }
    plausible("parking-search");
    const toCoords = getCoord(toLocation);
    
    await router.push(`/p/${toCoords}`);
  }

  return (
    <>
      <main className="p-4 mx-auto max-w-3xl">
        <h1 className='text-white text-[2rem] font-light'>Find bike parking near your destination</h1>
        <AsyncTypeahead
          className='my-4'
          isLoading={isLoading}
          labelKey={(option: any) => option.text}
          id="to"
          filterBy={() => true}
          onSearch={fetchOptions}
          // renderMenuItemChildren={renderMenuItemChildren}
          onChange={(selected) => {
            selectLocation(selected, setToLocation);
          }}
          placeholder="Where are you going?"
          options={options}
          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
            <Input
              {...inputProps}
              className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
              ref={(input) => {
                // Be sure to correctly handle these refs. In many cases, both can simply receive
                // the underlying input node, but `referenceElementRef can receive a wrapper node if
                // your custom input is more complex (See TypeaheadInputMulti for an example).
                inputRef(input);
                referenceElementRef(input);
              }}
            />
          )}
          renderMenu={(results, menuProps) => (
            <Menu {...menuProps} className="hs-dropdown-menu transition-[opacity,margin] duration w-72 hidden z-10 mt-2 min-w-[15rem] bg-white shadow-md rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700 dark:divide-gray-700">
              {(results as Record<string, any>).map((result, index) => (
                <MenuItem option={result} position={index} key={index} className="flex flex-col gap-x-3.5 py-2 px-3 rounded-md text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300">
                  <span className='font-bold'>{result.text}</span>
                  <span className='text-sm'>{result.place_name}</span>
                </MenuItem>
              ))}
            </Menu>
          )}
        />
        {/* <div className="text-white w-full text-center">Or</div>
        <div className="my-3">
          <Button
              type='secondary'
              label='Use Current Location'
            />
        </div>
        <hr /> */}
        <div className="my-3">
          <Button
            variant='primary'
            label='Find Bike Parking' 
            disabled={toLocation === undefined} 
            onClick={goToParkingPicker}
          />
          </div>
      </main>
    </>
  )
}

export default Home
